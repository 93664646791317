@tailwind base;

@layer base{
    body {
        @apply text-slate-900 dark:text-gray-200 h-full bg-theme transition-all duration-300 ease-in-out;
    }
    main {
        /* Header offset */
        @apply pt-[96px] md:pt-[111px];
    }
    h1 {
        @apply text-3xl font-bold;
    }
    h2 {
        @apply text-2xl font-bold;
    }
    h3 {
        @apply text-xl font-bold;
    }
    h4 {
        @apply text-lg font-bold;
    }
    li {
        @apply text-base font-normal;
    }
    /* TODO invesigate if this can be refactored/removed */
    .space-between {
        @apply flex flex-col justify-between h-screen;
    }
}