@tailwind utilities;

@layer utilities {
    .zoom-in-out {
        @apply hover:cursor-pointer transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-300;
    }

    .bg-theme {
        @apply bg-white dark:bg-dark-gray;
    }

    .bg-instagram {
        @apply bg-gradient-to-b md:bg-gradient-to-r from-purple-700 via-pink-500 to-yellow-400;
    }

    .bg-blue-gradient {
        @apply bg-gradient-to-r from-cyan-500 to-blue-400;
    }

    .bg-teal-gradient {
        @apply bg-gradient-to-l from-cyan-500 via-teal-500 to-blue-700;
    }

    .bg-dark-gradient {
        @apply bg-gradient-to-l md:bg-gradient-to-tr from-gray-900 to-gray-700;
    }

    .py-block {
        @apply py-[1.5rem] md:py-[3rem];
    }

    .modal-active {
        touch-action: none;
        -webkit-overflow-scrolling: none;
        overflow: hidden;
        overscroll-behavior: none;
    }

    .border-red {
        @apply border-2 border-red-500;
    }

    .border-blue {
        @apply border-2 border-blue-500;
    }

    .border-green {
        @apply border-2 border-green-500;
    }

    .invert-icons {
        -webkit-filter: invert(100%);
        filter: invert(100%);
    }

    .flex-col-03 {
        @apply flex flex-col gap-y-[0.3rem];
    }

    .flex-col-05 {
        @apply flex flex-col gap-y-[0.5rem];
    }

    .flex-col-1 {
        @apply flex flex-col gap-y-[1rem];
    }

    .flex-col-2 {
        @apply flex flex-col gap-y-[2rem];
    }

    .zoom-image:hover img {
        @apply scale-110 transition-all duration-500;
    }
}