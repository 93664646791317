@tailwind components;

@layer components {
    #banner-carousel .image img {
        @apply h-[350px] md:h-[700px] object-cover;
    }

    #footer .item {
        @apply flex flex-col text-center;
    }

    #site-header .header-logo img {
        @apply w-[180px] md:w-[250px];
    }

    #site-header #nav-links .nav {
        @apply flex gap-x-[2rem];
    }

    #site-header #nav-links .nav .active {
        @apply underline;
    }

    #site-header nav img {
        @apply hover:cursor-pointer h-[20px];
    }

    .free-text-block {
        @apply grid gap-y-[4rem] px-0 md:px-4 lg:px-6 w-full py-[1rem] md:py-[2rem];
    }

    .free-text-block .container {
        @apply justify-center items-center;
    }

    .free-text-block .inner :not(img) {
        @apply px-[1rem];
    }

    .free-text-block .inner img {
        @apply py-[1rem] md:py-0;
    }

    .image-gallery .gallery img {
        @apply aspect-square object-cover hover:cursor-pointer;
    }

    #lightgallery.gallery-grid {
        @apply grid grid-cols-1 md:grid-cols-3 md:gap-[1rem];
    }

    .owl-carousel.filmstrip .image {
        @apply flex flex-col gap-y-[1rem];
    }

    .owl-carousel.filmstrip .image img {
        @apply h-[350px] aspect-square object-cover;
    }

    .page {
        @apply w-full;
    }

    .schedule-list li {
        @apply flex justify-between text-[1.125rem];
    }
}